import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query WorkerManagement {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Worker Management',
    subtitle: `Your workers are your most valuable assets. Employees need mentoring, guidance, supervision, and direction so that they can thrive. That’s why we offer robust administrative and supervisory tools that allow you to deep dive into worker dashboards, organize personal information, track credentials, and create organizational structures so that you can boost compliance and monitor their successes.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: "/features/worker-management/worker-management-banner.png"
  }

  const seo = {
    title: 'Workhub | Free Worker Management Software Admin Ongoing Tasks',
    description: 'Free Workforce Management Software that allows you to manage employee profiles, assign supervisors, track compliance  & monitor your workers tasks.',
    image: '/features/worker-management/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Organizational Structure",
      para: "Create a hierarchy of workers and supervisors so that employees know who to report to, and supervisors have a clear picture of who they need to support and manage. ",
    },
    {
      title: "Supervisory Tools",
      para: "We provide a clean interface that makes it easy for Supervisors to view their subordinate’s ongoing tasks and compliance. ",
    },
    {
      title: "Filter by Credentials",
      para: "Admins can filter workers by credentials to determine who is qualified to work on certain jobsites. ",
    },
    {
      title: "Full Dashboard View",
      para: "Click on individual workers to gain a full view of their dashboard, compliance, and outstanding items. ",
    },
    {
      title: "Charts and Trends",
      para: "Our software allows you to monitor company-wide compliance trends based on data from each worker. ",
    },
    {
      title: "Greenhand",
      para: "Designate new and inexperienced workers as ‘Greenhands’ so that they can receive the supervision and mentorship they need to succeed. ",
    },
  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">

    

      <section className="feature-section">
        <div className="container" >
          <div className="row switch-children">

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInLeft" delay="0.9s">
              <img className="feature-img-right" src="/features/worker-management/worker-management-supervisor.png"
              alt="Admin assigning supervisor"
               /></ReactWOW>

            </div>


            <div className="col-lg-6 feature-text">
            <h2>Organizational Structure & Supervisory Tools </h2>
            <p>Our software makes it easier to perform supervisory duties and lets your employees know they are being held accountable. Workers are assigned to a supervisor that tracks their compliance and ongoing tasks and each supervisor gets an email at a selected frequency to let them know who’s keeping up-to-date, and who needs a nudge. </p>

            <p>Plus, supervisors can quickly check who has seen an important correspondence like a bulletin or alert. This is extraordinarily handy if there’s an unexpected change in schedule, accident, health concern, or any other extenuating circumstance that will change the course of the workday. </p>
            </div>
          </div>
        </div>
      </section>


      <section className="feature-section">
        <div className="container" >
          <div className="row">

        
            <div className="col-lg-6 feature-text">


              <h2 style={{marginBottom: '2rem'}}>Admin Capabilities & Worker Management Tools</h2>

              <p>The Worker tab in the admin panels lets you do more than just add new employees into the system. Admins can:</p>

             <ul class="checklist">
                 <li>filter workers by credentials to see who's qualified for certain jobsites</li>
                 <li>click on individual workers to gain a full view of their dashboard, compliance, and outstanding items</li>
                 <li>view a supervisor’s subordinates</li>
                 <li>view compliance charts and trends for all workers</li>
                 <li>view incidents in which a worker was involved and the status of their illness or injury, if applicable</li>
             </ul>

            </div>

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInRight" delay="0.9s">

              <img className="feature-img-left" src="/features/worker-management/worker-management-admin.png"
              alt="Forms admin view"
                 />

                </ReactWOW>

            </div>

          </div>
        </div>
      </section>



      <section className="feature-section">
        <div className="container" >
          <div className="row switch-children">

            <div className="col-lg-6 feature-img">

            <ReactWOW animation="fadeInLeft" delay="0.9s">

              <img 
            className="feature-img-right"
              src="/features/worker-management/worker-mangement-greenhand.png"
              alt="Screen with greenhand view"
                />

                </ReactWOW>

            </div>


            <div className="col-lg-6 feature-text">

              <h2>Greenhand</h2>

              <p>As Charlotte Bronte famously said, “What is so headstrong as youth? What so blind as inexperience?” New employees who typically require mentorship and supervision, can be less efficient, and more prone to workplace incidents and blunders. Our software allows you to designate new employees as Greenhands which helps ensure they get the support they need, while boosting productivity, and preventing accidents.</p>

              <p>Not every new hire will be considered a Greenhand. In fact, John who just transitioned companies and already has 20 years’ experience likely doesn’t need a green hand beside his profile picture and may even be a little offended at the label. Our software doesn’t assume that every new person requires this distinction. Admins determine who needs the little green hand symbol and how long they carry that moniker.</p>

            </div>
          </div>
        </div>
      </section>

      <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>

    </Layout>
  )
}